import axiosInstance from '@/services/axiosInstance'

async function getPriceProducts (startDate, endDate, data) {
  const req = {
    url: `pricingAnalysis/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getPriceProducts }
