<template>
  <div class="pl-10 -ml-10 2xl:-ml-0 2xl:pl-10 2xl:pr-6 card-wrapper">
    <div
      :class="[checked ? 'selected border-primary text-white':'border-transparent', {'opacity-30': !row.approved}]"
      class="card bg-white rounded-lg flex mb-2 transition-all duration-150 shadow hover:shadow-lg border-2 border-solid  hover:border-primary"
    >
      <div class="w-20 bg-white flex-shrink-0 p-1 rounded-lg relative flex items-center justify-center">
        <img
          class="w-full object-contain max-h-20"
          :src="row.imageUrl"
          :alt="row.name"
        >
        <div class="select-item absolute top-0 -left-7 flex h-full items-center">
          <label class="checkbox-hover">
            <input
              type="checkbox"
              :name="row.idPrice"
              v-model="localChecked"
              @change="onChange"
              class="check-custom"
            >
            <span class="check-toggle"></span>
          </label>
        </div>
      </div>
      <div
        :class="[checked ? 'bg-secondary':'']"
        class="flex items-center w-full relative"
      >
        <div
          :style="`width: calc(${progressBarWidth(row.visualRank)} - 8px);`"
          :class="[checked ? 'bg-primary ':'bg-primary-100 ']"
          class="absolute card-progress top-1 left-1 rounded-lg z-0"
        />
        <div @click="onClick(row)" class="pl-3 py-3 pr-4 overflow-hidden flex-grow w-60 relative z-10 product-name">
          <span class="text-xs uppercase font-semibold opacity-75">{{ row.brandName }}</span>
          <h3 class="font-medium truncate leading-tight">
            {{ row.name }}
            <a
              :href="row.link"
              target="_blank"
              alt="Ficha del producto en su web de venta. Se abrirá en página a parte"
              class="tr-external-link"
              v-tooltip="'Ver ficha - Enlace Externo'"
            >
              <base-icon
                name="external"
                size="xs"
              />
            </a>
          </h3>
        </div>
        <div class="flex items-center flex-shrink-0 space-x-3 px-4 relative z-10 text-secondary">
          <span
            :class="[checked ? 'bg-secondary-800 text-white':'bg-primary-50']"
            class="size-data flex items-center justify-center font-semibold font-mono h-7 rounded-lg text-sm px-2 relative"
          >
            <span class="label-values absolute -top-4 left-0 text-xs uppercase opacity-50 text-center w-full">Price</span>
            {{ row.pvp | money(countryCode) }}
          </span>
          <span class="size-data bg-primary-50 flex items-center justify-center font-semibold font-mono h-7 rounded-lg text-sm relative">
            <span class="label-values absolute -top-4 left-0 text-xs uppercase opacity-50 text-center w-full">IVR</span>
            {{ row.visualRank | percent }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pricing-analysis-item-card',
  props: {
    row: {
      type: [Object],
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      localChecked: this.checked
    }
  },
  computed: {
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    }
  },
  methods: {
    progressBarWidth: function (value) {
      let roundVi = Math.round(value)
      if (roundVi > 100) {
        roundVi = 100
      }
      const percent = roundVi + '%'
      return percent
    },
    onClick: function (product) {
      this.$emit('click', product)
    },
    onChange: function () {
      this.$emit('change', this.row.idPrice, this.localChecked)
    }
  },
  watch: {
    checked: function (value) {
      this.localChecked = value
    }
  }
}
</script>

<style lang="scss" scoped>

.card-wrapper {
  .label-values {
    visibility: hidden;
  }
  &:hover {
    .select-item {
      visibility: visible;
    }
    .label-values {
      visibility: visible;
    }
  }
}

.card {
  .size-data {
    min-width: 100px;
  }
  .select-item {
    visibility: hidden;
  }
}
.card-progress {
  height: calc(100% - 8px);
}

.tr-external-link {
  opacity: 0;
  position: absolute;
  top: 50%;
  background: var(--primary-700);
  border-radius: 50%;
  transform: translateY(-50%);
  right: -10px;
  color: white;
  transition: right 0.2s, opacity 0.3s;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.product-name {
  cursor: pointer;
  &:hover {
    .tr-external-link {
      opacity: 1;
      right: 4px;
      &:hover {
        background: darken(#086dff, 10%);
      }
    }
  }
}

input[type="checkbox"].check-custom:hover ~ .check-toggle {
  @apply border-2 border-solid border-primary;
}

/* Styles for the focus state appearance of the custom checkbox */
input[type="checkbox"].check-custom:focus ~ .check-toggle {
  border-color: #b0d5ff;
  box-shadow: 0 0 0 2px rgba(23, 133, 255, 0.25);
}

input[type="checkbox"].check-custom {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

input[type="checkbox"].check-custom ~ .check-toggle {
  width: 1.1rem;
  height: 1.1rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  @apply border-2 border-solid border-primary;
}

/* Styles for the hover state appearance of the custom checkbox */
input[type="checkbox"].check-custom:hover ~ .check-toggle {
  @apply border-2 border-solid border-primary-500;
}

/* Styles for the checked state appearance of the custom checkbox */
input[type="checkbox"].check-custom:checked ~ .check-toggle {
  @apply bg-primary border-2 border-solid border-primary;
  background: var(--primary-700)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)
    center no-repeat;
  background-size: 80%;
}

input[type="checkbox"].check-custom:checked ~ .check-toggle {
  visibility: visible;
}
</style>
