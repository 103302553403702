<template>
  <div :class="{'h-full flex items-center justify-center' : !orderProducts.length}">
    <template v-if="orderProducts.length || !touch">
      <div class="wrapper-y-spacer"></div>
      <div :class="seeLastRecord" class="w-full md:w-11/12 md:mx-auto 2xl:w-full">
        <template v-for="(product, index) of orderProducts">
          <base-collapsible
            :key="index"
            :id="index"
            :overfFlow="isOverflow"
            v-bind:open="true"
            v-bind:heightLimit="false"
            customBlock="mb-4 pb-2 border-b border-solid border-primary-100"
            :customHeader="customHeader"
          >
            <template slot="headerRight">
              <div class="flex flex-col py-2">
                <h3 class="font-bold text-2xl leading-tight">{{ product.brandName }}</h3>
                <div class="flex items-center space-x-2">
                  <span>
                    <span class="text-sm opacity-70">{{ $t('app.visibility') }}</span> <b>{{ product.visualRank | percent }}</b></span>
                  <b>&middot;</b>
                  <span class="text-sm opacity-70">{{ $t('app.retailPrice') }}</span> <b>{{ product.pvp | money(countryCode) }}</b>
                </div>
              </div>
            </template>
            <template slot="content">
              <table-pricing-analysis
                v-if="view === 'table'"
                v-model="selectedItems"
                :data="product.productos"
                :loading="loading"
                @selected="onSelectProduct"
              />
              <pricing-analysis-card-view
                v-else
                v-model="selectedItems"
                :data="product.productos"
                :loading="loading"
                @selected="onSelectProduct"
              />
            </template>
          </base-collapsible>
        </template>

        <div class="wrapper-y-spacer"></div>
      </div>
    </template>
    <template v-else>
      <base-not-found />
    </template>

    <!-- boton Análisis de la competencia -->
    <banner-start-feature-layout :isOpen="!!selectedItems.length">
      <template slot="image">
        <img
          src="@/assets/img/svg-competencia.svg"
          alt="Iniciar análisis de la competencia"
        >
      </template>
      <template slot="body">
        <h4 class="leading-tight font-semibold">{{ $t('app.priceAnalysis') }}</h4>
        <span class="text-sm text-secondary-100 ">{{ $tc('app.selectedItemsCounter', selectedItems.length) }}</span>
      </template>
      <template slot="actions">
        <base-button
          size="xs"
          @click="resetCompetition"
          mode="secondary"
        >{{ $t('app.cancel') }}</base-button>
        <base-button
          size="xs"
          @click="toggleCompetitionModal"
        >{{ $t('app.start') }}</base-button>
      </template>
    </banner-start-feature-layout>

    <!-- filtros y modales -->
    <product-detail-panel
      v-if="productDetail"
      :key="productDetailKey"
      :panelOpen="true"
      :data="productDetail"
      @close="closeProductPanel"
    />
    <price-analysis-modal
      v-bind:horizontal="false"
      v-model="selectedItems"
      :toggle="competitionModal"
      @toggleModal="toggleCompetitionModal"
    />
  </div>
</template>

<script>
import TablePricingAnalysis from '@/components/tables/TablePricingAnalysis.vue'
import PricingAnalysisCardView from '@/components/PricingAnalysisCardView.vue'
import ProductDetailPanel from '@/components/ProductDetailPanel.vue'
import PriceAnalysisModal from '@/components/modalViews/PriceAnalysisModal.vue'
import BannerStartFeatureLayout from '@/layouts/BannerStartFeatureLayout.vue'
import moment from 'moment'
import { getPricingAnalysis } from '@/services/pricing-analysis/getPricingAnalysis'
import { deepClone } from '@/utils/general'
import { getCurrencyFormat, getDecimalFormat } from '@/i18n/numbers'
const { parse } = require('json2csv')

export default {
  name: 'pricing-analysis',
  components: {
    TablePricingAnalysis,
    PricingAnalysisCardView,
    PriceAnalysisModal,
    BannerStartFeatureLayout,
    ProductDetailPanel
  },
  data: function () {
    return {
      touch: false,
      isOverflow: false,
      competitionModal: false,
      productDetail: null,
      products: {},
      selectedItems: [],
      loading: false,
      csvLinks: [
        { ref: 'pricingAnalysis', title: this.$t('app.pricingAnalysis'), subtitle: this.$t('csv.file') }
      ]
    }
  },
  computed: {
    csvFieldOptions: function () {
      const locale = this.$store.getters['auth/locale']
      return this.$store.getters['filters/csvFieldOptions'](locale)
    },
    csvOptions: function () {
      const fields = []
      fields.push('brandName', 'name')
      fields.push(...this.csvFieldOptions)
      fields.push('pvp', 'visualRank', 'link', 'imageUrl')

      return {
        delimiter: ';',
        fields
      }
    },
    view: function () {
      return this.$store.getters['filters/view']
    },
    filterChangeCounter: function () {
      return this.$store.getters['filters/changeCounter']
    },
    selectedFilter1: function () {
      return this.$store.getters['filters/selectedDimensions'].filter1
    },
    selectedFilter2: function () {
      return this.$store.getters['filters/selectedDimensions'].filter2
    },
    selectedFilter3: function () {
      return this.$store.getters['filters/selectedDimensions'].filter3
    },
    selectedFilter4: function () {
      return this.$store.getters['filters/selectedDimensions'].filter4
    },
    selectedFilter5: function () {
      return this.$store.getters['filters/selectedDimensions'].filter5
    },
    groupId: function () {
      return this.$store.getters['filters/groupId']
    },
    categoryId: function () {
      return this.$store.getters['filters/categoryId']
    },
    siteId: function () {
      return this.$store.getters['filters/siteId']
    },
    startDate: function () {
      return this.$store.getters['filters/startDate']
    },
    endDate: function () {
      return this.$store.getters['filters/endDate']
    },
    minPrice: function () {
      return this.$store.getters['filters/minPrice']
    },
    maxPrice: function () {
      return this.$store.getters['filters/maxPrice']
    },
    countryCode: function () {
      return this.$store.getters['filters/countryCode']
    },
    seeLastRecord: function () {
      return {
        'pb-20': !!this.selectedItems.length,
        '2xl:pl-10 2xl:pr-6': this.view === 'table'
      }
    },
    customHeader: function () {
      return {
        '2xl:pl-10 2xl:pr-6': this.view !== 'table'
      }
    },
    brand: function () {
      return this.$store.getters['auth/user']?.nombreMarca
    },
    csvChangeCounter: function () {
      return this.$store.getters['app/csvCounter']
    },
    csvData: function () {
      let data = []
      const items = { ...this.products }
      for (const site in items) {
        const item = { ...items[site] }
        const products = item.productos.map(product => {
          const p = { ...product }
          p.pvp = product.pvp ? getCurrencyFormat(product.pvp, this.countryCode) : null
          p.visualRank = product.visualRank ? getDecimalFormat(product.visualRank) : null
          return p
        })
        data = data.concat(products)
      }
      return parse(data, this.csvOptions)
    },
    selectedHealthIndexFilter: function () {
      return this.$store.getters['filters/healthIndexFilter'].selected
    },
    activeOrder: function () {
      return this.$store.getters['filters/activeOrder']
    },
    orderProducts: function () {
      const products = deepClone(this.products)
      const activeOrder = this.activeOrder

      const items = []

      for (const key in products) {
        const item = products[key]

        let pvp = 0
        let visualrank = 0
        let positionAVG = 0
        let approved = false

        if (item.productos.length) {
          pvp = item.productos.reduce((acc, product) => acc + product.pvp, 0) / item.productos.length
          visualrank = item.productos.reduce((acc, product) => acc + product.visualRank, 0) / item.productos.length
          positionAVG = item.productos.reduce((acc, product) => acc + product.positionAVG, 0) / item.productos.length
          approved = item.productos.reduce((acc, product) => acc || product.approved, false)
        }

        item.brandName = key
        item.pvp = pvp
        item.visualRank = visualrank
        item.positionAVG = positionAVG
        item.approved = approved
        items.push(item)
      }

      if (activeOrder && !!activeOrder.order) {
        items.sort((a, b) => {
          if (typeof a[activeOrder.prop] === 'number') {
            return (a[activeOrder.prop] - b[activeOrder.prop]) * activeOrder.order
          } else if (typeof a[activeOrder.prop] === 'boolean') {
            return (a[activeOrder.prop] < b[activeOrder.prop] ? 1 : (a[activeOrder.prop] > b[activeOrder.prop] ? -1 : 0)) * activeOrder.order
          } else {
            return (a[activeOrder.prop] < b[activeOrder.prop] ? 1 : (a[activeOrder.prop] > b[activeOrder.prop] ? -1 : 0)) * activeOrder.order
          }
        })

        items.forEach(item => {
          item.productos.sort((a, b) => {
            if (typeof a[activeOrder.prop] === 'number') {
              return (a[activeOrder.prop] - b[activeOrder.prop]) * activeOrder.order
            } else if (typeof a[activeOrder.prop] === 'boolean') {
              return (a[activeOrder.prop] < b[activeOrder.prop] ? 1 : (a[activeOrder.prop] > b[activeOrder.prop] ? -1 : 0)) * activeOrder.order
            } else {
              return (a[activeOrder.prop] < b[activeOrder.prop] ? 1 : (a[activeOrder.prop] > b[activeOrder.prop] ? -1 : 0)) * activeOrder.order
            }
          })
        })
      }
      return items
    },
    productDetailKey: function () {
      return this.productDetail ? this.productDetail.idPrice : null
    }
  },
  created: async function () {
    this.$store.commit('app/setCsvLinks', this.csvLinks)
    try {
      this.$store.commit('app/setLoading', true)
      await this.downloadingData()
    } catch (error) {
      this.doFeedback()
    } finally {
      this.$store.commit('app/setLoading', false)
    }
  },
  methods: {
    downloadingData: async function () {
      try {
        this.loading = true
        await this.fetchVisualRank()
      } catch (error) {
        this.doFeedback()
      } finally {
        this.touch = true
        this.loading = false
      }
    },
    fetchVisualRank: async function () {
      const groupId = this.groupId
      const categoryId = this.categoryId
      const siteId = this.siteId
      const startDate = moment(this.startDate).format('DD-MM-YYYY')
      const endDate = moment(this.endDate).format('DD-MM-YYYY')
      const body = {
        precioMin: this.minPrice,
        precioMax: this.maxPrice
      }

      if (this.selectedHealthIndexFilter && this.selectedHealthIndexFilter.length === 1) {
        body.healthIndex = this.selectedHealthIndexFilter[0].toString()
      }
      if (this.selectedFilter1 && this.selectedFilter1.length) {
        body.filtro1 = this.selectedFilter1
      }
      if (this.selectedFilter2 && this.selectedFilter2.length) {
        body.filtro2 = this.selectedFilter2
      }
      if (this.selectedFilter3 && this.selectedFilter3.length) {
        body.filtro3 = this.selectedFilter3
      }
      if (this.selectedFilter4 && this.selectedFilter4.length) {
        body.filtro4 = this.selectedFilter4
      }
      if (this.selectedFilter5 && this.selectedFilter5.length) {
        body.filtro5 = this.selectedFilter5
      }

      const data = await getPricingAnalysis(groupId, categoryId, siteId, startDate, endDate, body)

      this.products = Object.keys(data.brandShareGlobal).reduce((acc, key) => {
        acc[key] = data.brandShareGlobal[key]
        if (acc[key].productos === null) {
          acc[key].productos = []
        }
        return acc
      }, {})

      this.$store.commit('filters/setHealthIndexOK', data.healthIndexOK)
      this.$store.commit('filters/setHealthIndexKO', data.healthIndexKO)
      this.$store.commit('filters/setFilters', {
        filter1: data.filtro1,
        filter2: data.filtro2,
        filter3: data.filtro3,
        filter4: data.filtro4,
        filter5: data.filtro5
      })

      this.$store.commit('filters/setResultsCounter', Object.keys(data.brandShareGlobal).length)
    },
    resetCompetition: function () {
      this.selectedItems = []
    },
    toggleCompetitionModal: function () {
      this.competitionModal = !this.competitionModal
    },
    downloadCsv: function () {
      const data = this.csvData
      const link = document.createElement('a')
      link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data)
      link.target = '_blank'
      link.download = 'pricing-analysis.csv'
      link.click()
    },
    onSelectProduct: function (product) {
      this.productDetail = product
    },
    closeProductPanel: function () {
      this.productDetail = null
    }
  },
  watch: {
    filterChangeCounter: function () {
      this.downloadingData()
    },
    csvChangeCounter: function () {
      this.downloadCsv()
    }
  },
  filters: {
    round: function (value) {
      return value.toFixed(2)
    }
  }
}
</script>
